import { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "./AuthContext";

let App;
const rootPath = window.location.pathname.split("/").slice(0, 3).join("/");
switch (rootPath) {
    case "/tools/configure":
        App = lazy(() => import("./configure/App" /* webpackChunkName: "configure" */));
        break;
    case "/tools/fertilize":
        App = lazy(() => import("./fertilize/App" /* webpackChunkName: "fertilize" */));
        break;
    case "/tools/sow":
        App = lazy(() => import("./sow/App" /* webpackChunkName: "sow" */));
        break;
    case "/tools/soc":
        App = lazy(() => import("./soc/App" /* webpackChunkName: "soc" */));
        break;
    case "/tools/admin":
        App = lazy(() => import("./admin/App" /* webpackChunkName: "admin" */));
        break;
    case "/tools/dora":
        window.location.assign("https://dora." + window.location.hostname.replace("app.", ""));
        break;
    case "/tools/sample":
        App = lazy(() => import("./sample/App" /* webpackChunkName: "sample" */));
        break;
    case "/tools/drive":
        App = lazy(() => import("./drive/App" /* webpackChunkName: "drive" */));
        break;
    case "/tools/inspect":
        App = lazy(() => import("./inspect/App" /* webpackChunkName: "inspect" */));
        break;
    default:
        App = lazy(() => import("./index/App" /* webpackChunkName: "index" */));
        break;
}

if (App) {
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(<AuthProvider><Suspense><App /></Suspense></AuthProvider>);
}

